import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import {
  ChangeEvent,
  type Dispatch,
  type SetStateAction,
  useEffect,
  useId,
  useState,
} from 'react'

import { DropdownArrow } from '@/features/common-blocks'
import { useElementHeight } from '@/hooks/useElementRect'
import { colors } from '@/theme/variables'

import { fieldStyles } from '../../styles/fieldStyles'
import type { ConditionsJSON } from './Form'

type Props = {
  data?: Queries.FormSelectFieldFragment | null
  onChange: (name: string, value: string) => void
  conditionalFields?: ConditionsJSON['fields'] | undefined
  setConditionData: Dispatch<SetStateAction<{ [name: string]: string }>>
  isHidden?: boolean
}

const FormSelectField = ({
  data,
  onChange,
  conditionalFields,
  setConditionData,
  isHidden,
}: Props): JSX.Element => {
  const name = data?.label ? kebabCase(data?.label) : undefined

  const [shrink, setShrink] = useState(false)
  const [value, setValue] = useState('')

  const handleShrink = () => {
    if (value.length > 0) {
      setShrink(true)
    } else {
      setShrink(false)
    }
  }

  useEffect(() => {
    if (value.length > 0) {
      setShrink(true)
    }
  }, [value])

  const allOptions =
    data?.options &&
    data.options.flatMap(option => {
      if (option?.__typename === 'DatoCmsFormSelectOption') {
        return option
      }
      if (option?.__typename === 'DatoCmsFormSelectGroup') {
        return option.options?.map(suboption => suboption)
      }
    })

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const idToOptionObject = allOptions?.find(
      option => option?.id === e.target.value
    )
    setValue(idToOptionObject?.value || '')
  }

  useEffect(() => {
    if (isHidden) {
      name && onChange(name, '')
    } else {
      name && onChange(name, value)
    }
  }, [onChange, name, value, isHidden])

  useEffect(() => {
    const affectsConditions = conditionalFields?.some(
      field => field.showIf.label === data?.label
    )
    if (affectsConditions && data?.label) {
      setConditionData(prev => ({ ...prev, [data.label || '']: value }))
    }
  }, [conditionalFields, setConditionData, data, value])

  const uniqueId = useId()

  const [labelRef, setLabelRef] = useState<HTMLElement | null>(null)
  const labelHeight = useElementHeight(labelRef)

  const [activeOptionRef, setActiveOptionRef] =
    useState<HTMLElement | null>(null)
  const activeOptionHeight = useElementHeight(activeOptionRef)

  const styles = {
    select: css`
      appearance: none;
      color: transparent;
      min-height: max(
        3.333em,
        2em + ${labelHeight || 0}px,
        ${activeOptionHeight || 0}px
      );
      transition: min-height 150ms ease;

      /* hide text in webkit */
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-text-fill-color: transparent !important;
      }
      option {
        color: var(--text-color);
        &:disabled {
          color: var(--text-color);
          opacity: 0.67;
        }
      }
    `,
    inputValue: css`
      position: absolute;
      display: block;
      pointer-events: none;
      line-height: 1.333;
    `,
    arrow: css`
      position: absolute;
      top: 50%;
      right: 1.5em;
      transform: translateY(-33%);
      pointer-events: none;
      @media (hover: hover) {
        div:hover > & {
          color: var(--textHighlight);
        }
      }
      div:focus-within > & {
        background-color: ${colors.blue};
      }
    `,
  }

  if (isHidden) {
    return (
      <input
        type="hidden"
        name={name}
        aria-hidden
      />
    )
  }

  return (
    <div css={fieldStyles.container(data?.width)}>
      <label
        htmlFor={name + uniqueId}
        css={[
          fieldStyles.label,
          shrink && fieldStyles.shrink,
          data?.required && fieldStyles.required,
        ]}
        ref={node => setLabelRef(node)}
      >
        {data?.label}
      </label>
      <DropdownArrow css={styles.arrow} />
      {value && (
        <div
          css={[fieldStyles.input, styles.inputValue]}
          aria-hidden
          ref={node => setActiveOptionRef(node)}
        >
          {value}
        </div>
      )}
      <div css={fieldStyles.inputBase}>
        <select
          css={[fieldStyles.input, styles.select]}
          name={name}
          id={name + uniqueId}
          required={data?.required || undefined}
          onChange={handleChange}
          onFocus={handleShrink}
          onBlur={handleShrink}
          defaultValue=""
        >
          <option
            value=""
            disabled
            aria-hidden
          >
            {data?.label}
          </option>
          {data?.options?.map(option => {
            if (option?.__typename === 'DatoCmsFormSelectOption') {
              return (
                <option
                  key={option.id}
                  value={option.id}
                >
                  {option.value}
                </option>
              )
            }
            if (option?.__typename === 'DatoCmsFormSelectGroup') {
              return (
                <optgroup
                  label={option.id}
                  key={option.id}
                >
                  {option.options?.map(suboption => (
                    <option
                      key={suboption?.id}
                      value={suboption?.id}
                    >
                      {suboption?.value}
                    </option>
                  ))}
                </optgroup>
              )
            }
          })}
        </select>
      </div>
    </div>
  )
}

export const FormSelectFieldFragment = graphql`
  fragment FormSelectField on DatoCmsFormSelectField {
    __typename
    id: originalId
    label
    options {
      ... on DatoCmsFormSelectGroup {
        ...FormSelectGroup
      }
      ... on DatoCmsFormSelectOption {
        ...FormSelectOption
      }
    }
    required
    width
  }
  fragment FormSelectGroup on DatoCmsFormSelectGroup {
    __typename
    id: originalId
    label
    options {
      ...FormSelectOption
    }
  }
  fragment FormSelectOption on DatoCmsFormSelectOption {
    __typename
    id: originalId
    value
  }
`

export default FormSelectField
